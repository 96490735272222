type SLUG_KEYS = 'c' | 'scat' | 'b';

const SLUG_TYPES = {
  categoryId: 'categoryId',
  subCategoryId: 'subCategoryId',
  brandId: 'brandId',
} as const;

const MAPPING_KEYS: Record<string, SLUG_KEYS> = {
  categoryId: 'c',
  subCategoryId: 'scat',
  brandId: 'b',
} as const;

export const getCategoryUrl = ({
  segment,
  categoryId,
  categoryUrlKey,
  subCategoryId,
  subCategoryUrlKey,
  brandId,
  brandKey,
  specialPage,
  queryString,
}: {
  segment?: string;
  categoryId?: string;
  categoryUrlKey?: string;
  subCategoryId?: string | null;
  subCategoryUrlKey?: string | null;
  brandId?: string;
  brandKey?: string;
  specialPage?: string;
  queryString?: string;
}) => {
  const mappingSlugAndId: Partial<Record<SLUG_KEYS, string>> = {};

  let url = `/c`;

  if (segment) {
    url = `${url}/${segment}`;
  }

  if (categoryId && categoryUrlKey) {
    url = `${url}/${categoryUrlKey}`;
    mappingSlugAndId.c = categoryId;
  }

  if (subCategoryId && subCategoryUrlKey) {
    url = `${url}/${subCategoryUrlKey}`;
    mappingSlugAndId.scat = subCategoryId;
  }

  if (brandId && brandKey) {
    url = `${url}/${brandKey}`;
    mappingSlugAndId.b = brandId;
  }

  if (specialPage) {
    url = `${url}/${specialPage}`;
  }

  Object.keys(SLUG_TYPES).forEach((slug) => {
    const slugKey = MAPPING_KEYS[slug];
    const value = mappingSlugAndId[slugKey];

    if (value) {
      url = `${url}/${slugKey}-${value}`;
    }
  });

  if (queryString) {
    url += `?${queryString}`;
  }

  return url;
};
