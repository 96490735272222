export const enum FALLBACK_IMAGE {
  women = 'cms/categories/main/sub/ViewAllWOMEN.jpg',
  men = 'cms/categories/main/sub/ViewAllMEN.jpg',
  kids = 'cms/categories/main/sub/ViewAllGIRL.jpg',
  essentials = 'cms/categories/main/sub/ViewAllESSENTIALS.jpg',
  beauty = 'cms/beauty/categories/main/sub/ViewAllBEAUTY.jpg',
  hobbies = 'cms/hobbies/categories/main/sub/ViewAllHOBBIES.jpg',
  home = 'cms/home/categories/main/sub/ViewAllHOME.jpg',
  lifestyle = 'cms/lifestyle/categories/main/sub/ViewAllHOME.jpg',
  luxury = 'cms/luxury/categories/main/sub/ViewAllLUXURY.jpg',
  sports = 'cms/sports/categories/main/sub/ViewAllSPORTS.jpg',
}
