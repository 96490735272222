import { Button, ButtonVariant } from '@zalora/zui';
import { WishlistIcon, WishlistSelectedIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { FC, MouseEvent, useMemo, useState } from 'react';
import { addToWishlist, getFirstWishlistItem, removeWishlistItem } from 'api/wishlist';
import Image from 'components/Image';
import { Routes } from 'constants/routes';
import useUser from 'hooks/api/useUser';
import { useWishlist } from 'hooks/wishlist/useWishlist';
import { useWishlistItems } from 'hooks/wishlist/useWishlistItems';
import { redirectAndAttachUrl } from 'utils/navigation';
import { captureError } from 'utils/raven';
import { getWishlistTotal, isSkuInWishlist, removeSkuFromWishlistData } from 'utils/wishlist';

const LOADING_ICON_SRC = '/static-assets/images/loading_icon.gif';

interface Props {
  className?: string;
  sku: string;
  simpleSku?: string;
  selectedSizeSystem?: string;
  onToggle?: (isAdded: boolean) => void;
  buttonVariant?: ButtonVariant;
  dataTestId?: string;
  activeIconClassName?: string;
}

const wishlistIconClassName = '!size-8 cursor-pointer';

const WishlistButton: FC<Props> = ({
  className,
  activeIconClassName,
  sku,
  simpleSku,
  selectedSizeSystem,
  buttonVariant = 'link',
  dataTestId,
  onToggle,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: user } = useUser();
  const { data: wishlistData, mutate: mutateWishlist } = useWishlist();
  const wishlistItems = useWishlistItems();
  const simpleOrConfigSku = simpleSku || sku;

  // currently, we don't limit the number of items in wishlist
  // in real case, there are users who add more than 100 items to their wishlist
  // consider to use memo to avoid for loop
  const isSelected = useMemo(
    () => isSkuInWishlist(simpleOrConfigSku, wishlistItems),
    [wishlistItems, simpleOrConfigSku],
  );

  const handleAddToWishlist = async () => {
    await addToWishlist(simpleOrConfigSku, simpleSku ? selectedSizeSystem : undefined);

    const addedWishlistItem = await getFirstWishlistItem();

    if (!addedWishlistItem || !wishlistData) {
      return;
    }

    mutateWishlist(
      {
        ...wishlistData,
        Items: [addedWishlistItem, ...wishlistItems],
        TotalCount: getWishlistTotal(wishlistData) + 1,
      },
      { revalidate: false },
    );
  };

  const handleRemoveFromWishlist = async () => {
    await removeWishlistItem({ simpleSku, configSku: sku });

    if (!wishlistData) {
      return;
    }

    const newWishlistData = removeSkuFromWishlistData(wishlistData, simpleOrConfigSku);

    mutateWishlist(newWishlistData, { revalidate: false });
  };

  const handleToggleWishlist = async (isAdded: boolean) => {
    setIsSubmitting(true);

    try {
      if (isAdded) {
        await handleAddToWishlist();
      } else {
        await handleRemoveFromWishlist();
      }

      if (onToggle) {
        onToggle(isAdded);
      }
    } catch (error) {
      // Don't need to show toast error here, if any error, the wishlist icon won't be updated
      captureError('Error when remove wishlist item', {
        error,
        params: { isAdded, simpleOrConfigSku },
        tag: 'handler',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onWishlistClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!user) {
      redirectAndAttachUrl(Routes.LOGIN, window.location.href);

      return;
    }

    await handleToggleWishlist(!isSelected);
  };

  const renderWishlistIcon = () => {
    if (isSelected) {
      return (
        <WishlistSelectedIcon
          className={wishlistIconClassName}
          innerClassName={activeIconClassName}
        />
      );
    }

    return <WishlistIcon className={wishlistIconClassName} />;
  };

  return (
    <Button
      data-test-id={dataTestId || 'wishlistBtn'}
      variant={buttonVariant}
      className={clsx('bg-white', className, {
        added: isSelected,
      })}
      onClick={onWishlistClick}
      aria-label="Add To Wishlist"
    >
      {isSubmitting ? (
        <Image
          src={LOADING_ICON_SRC}
          alt="loading icon"
          height={32}
          width={32}
          className="mix-blend-multiply"
        />
      ) : (
        renderWishlistIcon()
      )}
    </Button>
  );
};

export default WishlistButton;
