import { ZDTCategory, ZDTSEO } from '@zalora/doraemon-ts';
import { TFunction } from 'next-i18next';
import { FALLBACK_IMAGE } from 'constants/categories';
import {
  CATEGORY_MENU,
  CategoryMenu,
  CategoryMenuItem,
  SegmentMenuItem,
} from 'constants/header-categories-configuration';
import { Country } from 'constants/ventures';
import { Segment } from 'types/Segment';
import { NavigationMenu, NavigationMenuCategory } from 'types/api/cms/NavigationMenu';
import { getCategoryUrl } from './catalog/get-category-url';
import { getStaticAsset } from './domains';
import { getEnvConfiguration } from './env-configuration';

export const MAX_SUB_CATEGORIES = 10;

export const buildCategoryMenuItem = (segment: string, categories: ZDTSEO.Category[]) => {
  return categories.reduce((acc, category) => {
    if (!category.UrlKey || !category.IdCatalogCategory) {
      return acc;
    }

    return [
      ...acc,
      {
        id: category.IdCatalogCategory,
        label: category.Parent || '',
        url: getCategoryUrl({
          segment,
          categoryUrlKey: category.UrlKey,
          categoryId: category.IdCatalogCategory,
        }),
        urlKey: category.UrlKey,
        cmsKey: `navigation_${segment}_${category.UrlKey}_promo`,
        segment,
        icon: category.IconImgUrl || '',
      },
    ];
  }, [] as CategoryMenuItem[]);
};

export const getSpecialCategories = (
  categories: CategoryMenuItem[],
  countryCode: string,
  t: TFunction,
): CategoryMenuItem[] => {
  if (!categories.length) {
    return [];
  }

  return categories.reduce((specialCategories, category) => {
    if (!category.countries || category.countries.includes(countryCode as Country)) {
      specialCategories.push({
        ...category,
        label: t(category.label, { defaultValue: category.label }),
        url:
          category.url ||
          (category.translateUrl &&
            t(category.translateUrl, { defaultValue: category.translateUrl })) ||
          '',
        icon: category.icon ? getStaticAsset(category.icon, false) : '',
      });
    }

    return specialCategories;
  }, [] as CategoryMenuItem[]);
};

export const getHeaderData = (
  segment: SegmentMenuItem | undefined,
  categories: ZDTSEO.Category[] | undefined,
  t: TFunction,
) => {
  if (!segment || !categories?.length) {
    return {};
  }

  const countryCode = getEnvConfiguration('CC') || '';
  const menuConfig: CategoryMenu | null = CATEGORY_MENU[segment.id as Segment];
  const sale = getSaleItem(menuConfig, t);
  const showCategories = [
    ...getSpecialCategories(menuConfig?.prefixCategories || [], countryCode, t),
    ...buildCategoryMenuItem(segment.id, categories),
    ...getSpecialCategories(menuConfig?.postfixCategories || [], countryCode, t),
  ];

  return {
    sale,
    showCategories,
  };
};

export const getSaleItem = (menuConfiguration: CategoryMenu | null, t: TFunction) => {
  const sale = menuConfiguration?.sale;

  if (sale) {
    return {
      ...sale,
      translateUrl: sale.translateUrl
        ? t(sale.translateUrl, { defaultValue: sale.translateUrl })
        : '',
      icon: getStaticAsset(sale.icon || '', false),
    };
  }

  return sale;
};

const standardizeSubCategory = (
  subCat: ZDTCategory.Category,
  category: CategoryMenuItem,
): NavigationMenuCategory => ({
  name: subCat.Name || '',
  url: getCategoryUrl({
    segment: category?.segment || '',
    categoryUrlKey: category?.urlKey,
    categoryId: category?.id,
    subCategoryUrlKey: subCat?.UrlKey || undefined,
    subCategoryId: subCat?.IdCatalogCategory || undefined,
  }),
});

export const standardizeSubCategories = ({
  category,
  subCategories,
  subSubCategories,
  navigationMenu,
}: {
  category: CategoryMenuItem;
  subCategories: ZDTCategory.Category[] | undefined | null;
  subSubCategories?: (ZDTCategory.Category[] | undefined)[];
  navigationMenu?: NavigationMenu;
}): NavigationMenuCategory[] => {
  if (!subCategories) {
    return [...(navigationMenu?.categories || [])];
  }

  return subCategories.map((subCat, idx) => ({
    ...standardizeSubCategory(subCat, category),
    items:
      subSubCategories?.[idx]?.map((subSubCat) => standardizeSubCategory(subSubCat, category)) ||
      [],
  }));
};

/**
 * Get combined subcategories list from category api and cms
 */
export const getCombinedSubCategories = (
  category: CategoryMenuItem | undefined,
  subCategories?: ZDTCategory.Category[] | undefined | null,
  navigationMenu?: NavigationMenu,
) => {
  if (!category || (!subCategories && !navigationMenu)) {
    return [];
  }

  // Combine subcategories from CMS and API
  const combinedSubCategories = [
    ...standardizeSubCategories({ subCategories, category }),
    ...(navigationMenu?.categories || []),
  ];

  return combinedSubCategories;
};

export const getPosition = (el: HTMLElement, parent: HTMLElement) => {
  const elBoundRect = el.getBoundingClientRect();
  const parentBoundRect = parent.getBoundingClientRect();

  const leftDiff = elBoundRect.left - parentBoundRect.left;
  const xPosition = Math.round(leftDiff / elBoundRect.width) + 1; // All columns has equal width

  const heightPortion = (el.offsetTop - parent.offsetTop) / parentBoundRect.height;
  const noRows = Math.round(parentBoundRect.height / 24) + 1; // 24px is average height of a row
  const yPosition = Math.round(heightPortion * noRows) || 1;

  return { xPosition, yPosition };
};

export const getStaticCdnLink = (path?: string, countryIso = 'sg') => {
  if (!path) {
    return '';
  }

  return `https://static-${countryIso}.zacdn.com/${path}`;
};

export const getCategoryFallbackImg = (countryIso = 'sg', segment?: string) => {
  const urlImageFallback = segment && getFallbackImage(segment) ? getFallbackImage(segment) : '';

  return getStaticCdnLink(urlImageFallback, countryIso);
};

export const getFallbackImage = (segment: string): string | undefined => {
  switch (segment) {
    case 'women': {
      return FALLBACK_IMAGE.women;
    }

    case 'men': {
      return FALLBACK_IMAGE.men;
    }

    case 'kids': {
      return FALLBACK_IMAGE.kids;
    }

    case 'essentials': {
      return FALLBACK_IMAGE.essentials;
    }

    case 'beauty': {
      return FALLBACK_IMAGE.beauty;
    }

    case 'hobbies': {
      return FALLBACK_IMAGE.hobbies;
    }

    case 'home': {
      return FALLBACK_IMAGE.home;
    }

    case 'lifestyle': {
      return FALLBACK_IMAGE.lifestyle;
    }

    case 'luxury': {
      return FALLBACK_IMAGE.luxury;
    }

    case 'sports': {
      return FALLBACK_IMAGE.sports;
    }

    default: {
      return undefined;
    }
  }
};
