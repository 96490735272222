import { Tag } from '@zalora/zui';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import VoucherIcon from './voucher.svg';

type Variant = 'fill' | 'outline';

export interface Props {
  label?: string;
  variant?: Variant;
  compressed?: boolean;
  keyInfoBlock?: boolean;
}

export const VoucherPill = ({
  children,
  label = '',
  variant = 'fill',
  compressed = false,
  keyInfoBlock = false,
}: PropsWithChildren<Props>) => {
  return (
    <Tag
      type="alert"
      className={clsx(
        'inline-flex items-center whitespace-nowrap rounded-lg',
        variant === 'outline' && 'border-[0.5px] border-red-60 bg-white',
        compressed ? 'h-[18px] gap-1 px-1' : 'h-6 gap-2',
        { 'h-6 gap-1 rounded-full': keyInfoBlock },
      )}
      data-test-id="voucherPill"
    >
      <VoucherIcon
        className={clsx('inline-block fill-current', compressed ? 'h-4 w-4' : 'h-6 w-6')}
      />
      <span className={clsx('font-bold', keyInfoBlock ? 'text-lg' : 'text-base')}>{children}</span>
      <span className="text-xs text-grey-80">{label}</span>
    </Tag>
  );
};
