import { FC, memo } from 'react';
import { FormatMoneyOptions, formatMoney } from 'utils/money';

interface Props {
  children: string | number | null | undefined;
  as?: 'string' | 'number';
  formatOptions?: FormatMoneyOptions;
}

const Money: FC<Props> = ({ children, as = 'string', formatOptions }) => {
  return (
    <>
      {/* Format not empty string and number (including 0) */}
      {typeof children !== 'undefined' && children !== null
        ? // Ignore type below as overload is private
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatMoney(children, as as any, formatOptions)
        : null}
    </>
  );
};

export default memo(Money);
