import clsx from 'clsx';
import Money from 'components/Money';
import { useOptimizelyStore } from 'stores/optimizely';
import * as optimizelySelector from 'stores/optimizely/selectors';
import { DiscountedPrice } from './DiscountedPrice';
import { IDiscountedPrice } from './types';

const toDiscountPercentage = (price: number, discountedPrice: number) => {
  const percent = Math.round(((price - discountedPrice) / price) * 100);

  return `-${percent}%`;
};

interface Props {
  isLoading?: boolean;
  originalPrice: number;
  discountedPrice: IDiscountedPrice;
}

export const Price = ({ isLoading, originalPrice, discountedPrice }: Props) => {
  const isPriceDisplayEnabled = useOptimizelyStore(optimizelySelector.isPriceDisplayEnabled);

  if (isLoading) {
    return (
      <div className="mt-1 flex animate-pulse flex-col gap-1">
        <div className="h-6 w-1/3 rounded bg-grey-10"></div>
        <div className="h-6 w-2/3 rounded bg-grey-10 desktop:hidden"></div>
      </div>
    );
  }

  const { price } = discountedPrice;
  const hasDiscounts = !isNaN(price) && price < originalPrice;

  return (
    <div
      className="mt-1 flex flex-col flex-wrap items-start gap-1 whitespace-nowrap text-base tablet:flex-row tablet:items-center"
      data-test-id="productPrice"
    >
      {hasDiscounts ? <DiscountedPrice {...discountedPrice} /> : null}
      <div className="flex flex-wrap gap-1 font-medium">
        <div
          className={clsx(hasDiscounts && 'text-grey-60 line-through')}
          data-test-id="originalPrice"
        >
          <Money
            formatOptions={{
              shouldRemoveFractionalNumbers: isPriceDisplayEnabled,
            }}
          >
            {originalPrice}
          </Money>
        </div>
        {hasDiscounts ? (
          <div
            className="text-sm leading-6 text-grey-100"
            data-test-id="discountPercentage"
          >
            {toDiscountPercentage(originalPrice, price)}
          </div>
        ) : null}
      </div>
    </div>
  );
};
