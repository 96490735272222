import { useTranslation } from 'next-i18next';
import Money from 'components/Money';
import { VoucherPill } from 'components/promotion/VoucherPill';
import { useOptimizelyStore } from 'stores/optimizely';
import * as optimizelySelector from 'stores/optimizely/selectors';
import { formatMoney } from 'utils/money';
import { IDiscountedPrice } from './types';

export const DiscountedPrice = ({ price, hasPriceRange, hasVoucher }: IDiscountedPrice) => {
  const { t } = useTranslation();
  const isPriceDisplayEnabled = useOptimizelyStore(optimizelySelector.isPriceDisplayEnabled);

  const roundedPrice = isPriceDisplayEnabled ? Math.round(price) : price;
  const formattedPrice = (
    <Money formatOptions={{ shouldRemoveFractionalNumbers: isPriceDisplayEnabled }}>
      {roundedPrice}
    </Money>
  );

  if (hasVoucher) {
    return (
      <VoucherPill
        variant="outline"
        compressed
      >
        {formattedPrice}
      </VoucherPill>
    );
  }

  return (
    <div
      className="whitespace-pre-wrap font-bold text-red-60"
      data-test-id="specialPrice"
    >
      {hasPriceRange ? (
        <span>
          {t('From {{price}}', {
            price: formatMoney(roundedPrice, 'string'),
          })}
        </span>
      ) : (
        formattedPrice
      )}
    </div>
  );
};
